<template>
  <transition name="modal" appear>
    <div class="aside__blackout modal-window-bg" @mousedown.self.left="close(true)">
      <section class="aside__section modal-window" :style="{ width: typeof modalWidth === 'string' ? modalWidth : modalWidth + 'px' }">
        <div v-if="withHeader" class="as-header" :class="`text-${headerAlign}`" :style="{padding: headerPadding}">
          <slot name="title">
            <h2 class="as-header__title">{{title}}</h2>
            <slot name="after-title"></slot>
          </slot>
          <icon-mini-close class="as-header__close" :width="24" :height="24" iconColor="aside" @click.native="close(true)" />
        </div>
        <div class="as-body" :style="{padding: bodyPadding, height: modalHeight || 'auto'}">
          <v-scroll-2 v-if="modalHeight">
              <slot></slot>
          </v-scroll-2>
          <slot v-else></slot>
        </div>
        <slot name="footer">
          <div v-if="withFooter" class="as-buttons" :style="{padding: footerPadding}">
            <div :class="`text-${footerAlign}`">
              <template v-if="withDelete">
                <icon-trash
                  class="delete-btn"
                  :width="38"
                  :height="30"
                  iconColor="danger"
                  hoverColor="white"
                  @click.native="$emit('delete')"
                />
                <a
                  v-if="link"
                  :href="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="pull-right"
                  style="line-height: 31px;height:30px;margin-right:20px"
                >{{ linkMessage }}</a>
              </template>
              <button
                v-if="errorButtonText"
                key="danger-btn"
                class="btn btn-danger pull-right"
                style="vertical-align: middle;"
                :disabled="deleteButtonDisabled"
                @click="onClickDangerBtn"
              >
                {{ errorButtonText }}
              </button>
              <button
                v-if="okButtonText"
                key="ok-btn"
                class="ok-btn btn"
                :class="'btn-' + okButtonColor"
                style="vertical-align: middle;"
                :disabled="okButtonDisabled"
                @click="$emit('success')"
              >
                {{ okButtonText }}
              </button>
              <a
                v-if="cancelBtnText"
                key="cancel-btn"
                style="vertical-align: middle;"
                class="cancel-btn btn"
                :class="'btn-' + cancelBtnColor"
                @click="close()"
                >{{cancelBtnText}}</a
              >
              <slot name="after-buttons"></slot>
            </div>
          </div>
        </slot>
      </section>
      <slot name="other"></slot>
      <v-modal-new v-if="isShowConfirm"></v-modal-new>
    </div>
  </transition>
</template>

<script>
import IconMiniClose from '../../icons/IconMiniClose.vue';
import IconTrash from '../../icons/IconTrash3.vue';
export default {
  name: "v-modal-new",
  components: {
    IconMiniClose,
    IconTrash
  },
  props: {
    withHeader: {
      type: Boolean,
      default: true
    },
    headerAlign: {
      type: String,
      default: "left"
    },
    title: String,
    headerLeft: String,
    headerRight: String,
    // footer
    withFooter: {
      type: Boolean,
      default: true
    },
    footerAlign: {
      type: String,
      default: "left"
    },
    headerPadding: String,
    bodyPadding: String,
    footerPadding: String,
    errorButtonText: String,
    withDelete: Boolean,
    okButtonColor: {
      type: String,
      default: "primary"
    },
    okButtonDisabled: Boolean,
    deleteButtonDisabled: Boolean,
    okButtonText: {
      type: String,
      default: "Сохранить"
    },
    cancelBtnText: {
      type: String,
      default: "Отмена"
    },
    cancelBtnColor: {
      type: String,
      default: "secondary"
    },
    width: {
      type: [Number, String],
      default: 500
    },
    height: {
      type: [Number, String]
    },
    isFullPage: Boolean,
    link: String,
    linkMessage: String
  },
  data() {
    return {
      isShowConfirm: false,
      modalWidth: this.isFullPage ? 'calc(100vw - 100px)' : this.width,
      modalHeight: this.isFullPage ? 'calc(100vh - 170px)' : this.height,
    };
  },
  watch: {
    width() {
      if (this.width && !this.isFullPage) this.modalWidth = this.width
    }
  },
  methods: {
    close(isConfirm) {
      this.$emit("close", isConfirm);
    },
    onClickDangerBtn() {
      // Заглушка
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s ease-in, top 0.2s ease-in;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
  top: -70px;
}
.aside__blackout {
  cursor: default;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100vh + 70px);
  z-index: 1002;
  & > .aside__section {
    z-index: 1;
    margin-bottom: 70px;
    border-radius: 16px;
    .as-header {
      padding: 20px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      position: relative;
      // border-radius: 10px 10px 0 0;
      & > .as-header__close {
        margin-left: auto;
        cursor: pointer;
        // width: 24px;
        // height: 24px;
        padding: 8px;
        border-radius: 6px;
        background-color: var(--aside-bg-98);
        &:hover, &:active {
          background: var(--aside-color-10);
        }
        &:active {
          opacity: 0.5;
        }
      }
      & > .as-header__title {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        // color: var(--main-color);
      }
    }
    .as-body {
      // padding: 15px;
    }
    .as-buttons {
      padding: 20px;
      .ok-btn {
        margin-right: 20px;
      }
      .delete-btn {
        height: 30px;
        padding: 6px 10px;
        border-radius: 4px;
        background-color: rgba(235, 87, 87, 0.15);
        vertical-align: middle;
        margin-left: 20px;
        float: right;
        cursor: pointer;
        &:hover {
          background-color: var(--color-danger);
        }
        &:active {
          opacity: .5;
        }
      }
    }
  }
}
</style>
