function checkSymbol(key) {
  return key.length === 1 && (key >= "0" && key <= "9" ||
    key >= "a" && key <= "z" ||
    key >= "A" && key <= "Z" ||
    key >= "а" && key <= "я" ||
    key >= "А" && key <= "Я" ||
    key === '-')
}
function toSerialCode(str) {
  const charsArray = {
    "Й": "Q",
    "Ц": "W",
    "У": "E",
    "К": "R",
    "Е": "T",
    "Н": "Y",
    "Г": "U",
    "Ш": "I",
    "Щ": "O",
    "З": "P",
    "Х": "[",
    "Ъ": "]",
    "Ф": "A",
    "Ы": "S",
    "В": "D",
    "А": "F",
    "П": "G",
    "Р": "H",
    "О": "J",
    "Л": "K",
    "Д": "L",
    "Ж": ";",
    "Э": "'",
    "Я": "Z",
    "Ч": "X",
    "С": "C",
    "М": "V",
    "И": "B",
    "Т": "N",
    "Ь": "M",
    "Б": ",",
    "Ю": ".",
  }
  return str.toUpperCase().split("").map(function (char) { return charsArray[char] || char; }).join("");
}
export {
  checkSymbol,
  toSerialCode
}